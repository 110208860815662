<template>
  <div class="categories">
    <Navbar/>  
    <div class="container schools-search">
        <div class="row">
            <div class="col-12 h1-wrapper">
                <h1>Школы</h1>
            </div>
        </div>

        <div class="row school-categorie-wrapper">
            <div class="col-12">
                <div class="title">
                    Языковые школы
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container af">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/">Alliance Française</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container elfe">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/">Elfe</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container france-langue">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/language-schools/france-langue">France Langue</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container accord">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/language-schools/accord">Accord</router-link>
                </div>
            </div>
        </div>

        <div class="row school-categorie-wrapper">
            <div class="col-12">
                <div class="title">
                    Бизнес-школы
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container skema">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/business-schools/skema">Skema</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container neoma">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/business-schools/neoma">Neoma</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container mbs">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/business-schools/mbs">Montpellier BS</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container pbs">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/business-schools/pbs">Paris BS</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container ipag">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/business-schools/ipag">Ipag</router-link>
                </div>
            </div>

        </div>

        <div class="row school-categorie-wrapper">
            <div class="col-12">
                <div class="title">
                    Школы искусства, моды и дизайна
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container maragoni">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/fashion-schools/istituto-marangoni">Istituto Maragoni</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container lisaa">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/fashion-schools/lisaa">Lisaa</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container chardon">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/">Atelier Chardon Savard</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container strate">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/fashion-schools/strate">Strate</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container florent">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/fashion-schools/cours-florent">Cours florent</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container iesa">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/fashion-schools/iesa">Iesa</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container clcf">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/">Clcf</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container itm">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/fashion-schools/itm">Itm</router-link>
                </div>
            </div>
        </div>

        <div class="row school-categorie-wrapper">
            <div class="col-12">
                <div class="title">
                    Кулинарные школы
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container ducasse">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/culinary-schools/ducasse">Ducasse</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container cordon">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/culinary-schools/cordon-bleu">Cordon Bleu</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container france-culinaire">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/">Institut culinaire de France</router-link>
                </div>
            </div>
        </div>

        <div class="row school-categorie-wrapper">
            <div class="col-12">
                <div class="title">
                    Инженерные школы
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container cytech">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/schools/engineer-schools/cy">Cy tech</router-link>
                </div>
            </div>
        </div>

        <div class="row school-categorie-wrapper">
            <div class="col-12">
                <div class="title">
                    Web/digital школы
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container hetic">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/">Hetic</router-link>
                </div>
            </div>
            <div class="col-6 col-lg-2 school-card">
                <div class="image-container digital-campus">
                </div>
                <div class="link-wrapper">
                    <router-link class="link-schools" to="/">Digital campus</router-link>
                </div>
            </div>
        </div>

        
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'

export default {
  name: 'BusinessSchools',
  components: {
    Navbar
  }
}
</script>
